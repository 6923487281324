.pdp-downloadables {
  display: grid;
  gap: 1.5rem;
  align-items: baseline;
  @include breakpoint-md {
    @include cols-2;
  }

  &__item {
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    h3 {
      color: map-get($colors, "primary");
      padding-top: 2.75rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid map-get($colors, "accent");
      text-align: center;
      margin-top: 0;
      margin-bottom: 2.25rem;
    }
  }

  &__expand-btn {
    margin: 0 auto;
    display: block;
    margin-bottom: 2.25rem;
    cursor: pointer;
    svg {
      pointer-events: none;
    }
  }

  &__empty {
    text-align: center;
    margin-bottom: 2rem;
    opacity: 0.5;
  }

  &__content {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.25rem;
    li {
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &__section-title {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: map-get($colors, "primary");
  }
}
