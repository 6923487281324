.transportation-info {
  padding-bottom: 4rem;
  &__title {
    margin-bottom: 2rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: 42% 55%;
    gap: 3%;
    @include below-md {
      grid-template-columns: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__block {
    h3 {
      line-height: 1;
    }
  }

  &__text {
    ul {
      list-style: disc;
      list-style-type: disc;
      padding-inline-start: 40px;

      li {
        padding-bottom: 0.5rem;
        font-size: 1rem;
        font-weight: 400;
        strong {
          font-weight: bold;
        }
        a {
          color: map-get($colors, "primary");
          text-decoration: underline;
        }
      }
    }
  }

  &__image {
    &-link {
      display: block;
      transition: opacity 0.3s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }

    &-top {
      margin-bottom: 1rem;
    }
  }
}
