@charset "UTF-8";
@import "~normalize.css/normalize.css";
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/** Config */
/**
 * Variables
 */
@font-face {
  font-family: "Colour Sans";
  src: url("../fonts/ColourSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Colour Sans";
  src: url("../fonts/ColourSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Segoe UI";
  src: url("../fonts/SegoeUI.ttf");
  font-weight: 400;
  font-style: normal;
}
body {
  background-color: #ffffff;
}

img {
  max-width: 100%;
}

section {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  overflow-x: hidden;
}
@media only screen and (min-width: 960px) {
  section {
    overflow-x: visible;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

img.rounded {
  border-radius: 1.25rem;
}

a {
  color: unset;
  text-decoration: unset;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.swiper-scrollbar-custom {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
  background-color: #dd713a;
}

section.full-width-image {
  margin-top: 5rem;
  height: 680px;
  background-size: cover;
  background-position: center center;
  background-color: #ccc;
}

.m-hidden {
  display: none;
}
@media only screen and (min-width: 960px) {
  .m-hidden {
    display: block;
  }
}

@media only screen and (min-width: 960px) {
  .d-hidden {
    display: none;
  }
}

.page-watermark {
  position: absolute;
  width: 100vw;
  aspect-ratio: 649/802;
  background-size: 100% 100%;
  z-index: -1;
}
@media only screen and (min-width: 960px) {
  .page-watermark {
    width: 50vw;
  }
}

.search-results__none, .search-results__found {
  padding-bottom: 2rem;
}
@media only screen and (min-width: 960px) {
  .search-results__none, .search-results__found {
    padding-bottom: 4rem;
  }
}
.search-results__item {
  padding-left: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #3c73a9;
}
.search-results__item h3 a {
  color: #dd713a;
}

.event-subscribe-form {
  display: grid;
  gap: 1rem;
}
.event-subscribe-form__cols-2 {
  gap: 1rem;
  display: grid;
}
@media only screen and (min-width: 960px) {
  .event-subscribe-form__cols-2 {
    grid-template-columns: auto auto;
  }
}
.event-subscribe-form input,
.event-subscribe-form textarea {
  width: 100%;
}
.event-subscribe-form input,
.event-subscribe-form textarea {
  border: 1px solid #3c73a9;
  border-radius: 0.5rem;
  padding: 1rem;
}
.event-subscribe-form p {
  margin: 0;
}
.event-subscribe-form input[type=submit] {
  margin-left: auto;
  width: auto;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
}

/** Common */
.container {
  padding: 0 1.75em;
  margin-left: auto;
  margin-right: auto;
  max-width: 80em;
  width: 100%;
}

.grid {
  display: grid;
}

/**
* Generates 12 grid columns and also responsive classes for each screen width
*/
.cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

.cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

.cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

.cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

.cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
}

.cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

/**
* Generates 4 grid-gap classes with each 1em difference up to 4em
*/
.gap-1 {
  grid-gap: 1em;
}

.column-gap-1 {
  grid-column-gap: 1em;
}

.row-gap-1 {
  grid-row-gap: 1em;
}

.gap-2 {
  grid-gap: 2em;
}

.column-gap-2 {
  grid-column-gap: 2em;
}

.row-gap-2 {
  grid-row-gap: 2em;
}

.gap-3 {
  grid-gap: 3em;
}

.column-gap-3 {
  grid-column-gap: 3em;
}

.row-gap-3 {
  grid-row-gap: 3em;
}

.gap-4 {
  grid-gap: 4em;
}

.column-gap-4 {
  grid-column-gap: 4em;
}

.row-gap-4 {
  grid-row-gap: 4em;
}

body {
  font-family: "Segoe UI", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
@media only screen and (min-width: 960px) {
  body {
    line-height: 1.33;
  }
}

.display {
  font-family: "Colour Sans", sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 1;
}
@media only screen and (min-width: 960px) {
  .display {
    font-size: 4.5rem;
  }
}

h1,
.h1 {
  font-family: "Colour Sans", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  animation: fadeIn;
  animation-duration: 1.2s;
}
@media only screen and (min-width: 960px) {
  h1,
.h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  color: #3c73a9;
  font-family: "Colour Sans", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1;
  animation: fadeIn;
  animation-duration: 1.2s;
}
@media only screen and (min-width: 960px) {
  h2,
.h2 {
    font-size: 2.5rem;
  }
}

.uppercase {
  text-transform: uppercase;
}

h3,
.h3 {
  font-family: "Colour Sans", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
}
@media only screen and (min-width: 960px) {
  h3,
.h3 {
    font-size: 1.5rem;
    line-height: 1.33;
  }
}
h3--light,
.h3--light {
  font-weight: 400;
}

p {
  line-height: 1.5;
}

.muted {
  opacity: 0.5;
}

/** Components */
/**
 * Components
 */
button {
  background: transparent;
  border: none;
  outline: none;
}

.button, .gform_body #field_1_8 button,
.wpcf7-submit {
  padding: 0.75rem 2rem;
  color: #ffffff;
  background-color: #3c73a9;
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  border-radius: 5rem;
  border: 2px solid #3c73a9;
  transition-duration: 200ms;
  cursor: pointer;
}
.button--center,
.wpcf7-submit--center {
  margin: 0 auto;
}
.button--outline,
.wpcf7-submit--outline {
  background-color: transparent;
  color: #3c73a9;
}
.button--outline:hover,
.wpcf7-submit--outline:hover {
  background-color: #3c73a9;
  color: #ffffff;
}
.button--download,
.wpcf7-submit--download {
  padding-left: 3rem;
}
.button--download::before,
.wpcf7-submit--download::before {
  content: "";
  background-size: 100% 100%;
  width: 18px;
  height: 20px;
  vertical-align: middle;
  margin-left: -24px;
  position: absolute;
  background-image: url("../images/download.svg");
}

.page-header {
  padding-top: 0rem;
}
.page-header > .container {
  display: grid;
}
@media only screen and (min-width: 960px) {
  .page-header > .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }
}
@media only screen and (min-width: 960px) {
  .page-header {
    padding-top: 5rem;
  }
}
.page-header .h3 {
  color: #000;
  margin-bottom: 0;
}
.page-header .h3 + p {
  margin-top: 0;
}
.page-header__content {
  grid-row-start: 2;
}
@media only screen and (min-width: 960px) {
  .page-header__content {
    grid-row-start: auto;
    padding-right: 8rem;
  }
}
.page-header__readmore {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 700;
  color: #dd713a;
  display: block;
  cursor: pointer;
}
.page-header__extra-content {
  text-align: justify;
}
.page-header__image {
  grid-row-start: 1;
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}
.page-header__image img {
  width: 100%;
  border-radius: 0;
}
@media only screen and (min-width: 960px) {
  .page-header__image {
    grid-row-start: auto;
    margin-right: calc(-50vw + 100%);
    margin-left: 0;
  }
  .page-header__image img {
    max-width: unset;
    width: 100%;
    min-height: 480px;
    max-height: 680px;
    object-fit: cover;
    object-position: center center;
  }
  .page-header__image img.rounded {
    border-top-left-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media only screen and (min-width: 960px) {
  .page-content .cols-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 8rem;
    align-items: center;
  }
}
.page-content h2 {
  color: #3c73a9;
}
@media only screen and (min-width: 960px) {
  .page-content__image {
    margin-left: calc(-50vw + 100%);
  }
  .page-content__image img {
    max-width: unset;
    width: 100%;
    max-height: 680px;
    object-fit: cover;
    object-position: center center;
  }
  .page-content__image img.rounded {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.side-effects {
  padding-top: 0;
  padding-bottom: 0;
}
@media only screen and (min-width: 960px) {
  .side-effects {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
    background-position: center center;
    background-size: cover;
  }
}
.side-effects__card {
  background-color: #f5f5f5;
  max-width: 744px;
  margin: 0 auto;
  padding-top: 4.5rem;
  padding-bottom: 4rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
@media only screen and (min-width: 960px) {
  .side-effects__card {
    background-color: #ffffff;
    border-radius: 1.25rem;
    padding: 2.5rem 3.25rem;
  }
}
.side-effects__title {
  margin-top: 0;
  position: relative;
}
.side-effects__title::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: -1.75rem;
  width: calc(100% + 3.5rem);
  height: 0.5rem;
  background-color: #dbdc62;
}
@media only screen and (min-width: 960px) {
  .side-effects__title::after {
    left: -3.25rem;
    width: calc(100% + 6.5rem);
  }
}
.side-effects__text {
  margin-bottom: 2.5rem;
}
.side-effects__actions {
  display: flex;
  justify-content: center;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 2rem;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(0.75rem);
  z-index: 150;
  display: grid;
  place-items: center;
  text-align: center;
}
.confirmation-modal h2 {
  margin-top: 0;
}
.confirmation-modal__box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 1.25rem;
  max-width: 540px;
}
.confirmation-modal__actions {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.confirmation-modal__actions button {
  margin-bottom: 1rem;
}
@media only screen and (min-width: 960px) {
  .confirmation-modal__actions button {
    margin-bottom: 0;
  }
}

/** Layout */
.header {
  padding-top: 1.4rem;
  padding-bottom: 1.1rem;
  position: relative;
}
.header__search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 120;
  transform: translateY(-100%);
  transition: ease-in 300ms;
}
.header__search.is-open {
  transform: translateY(0);
}
.header__search > .container {
  height: 100%;
  position: relative;
}
.header__search-trigger-close {
  position: absolute;
  right: 0.5rem;
  font-size: 2rem;
  cursor: pointer;
}
@media only screen and (min-width: 960px) {
  .header__search-trigger-close {
    right: 1.75rem;
  }
}
.header__search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.header__search-bar form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__search-bar form input[type=search] {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #3c73a9;
  border-radius: 0;
}
@media only screen and (min-width: 960px) {
  .header__search-bar form input[type=search] {
    padding: 0.75rem 2rem;
  }
}
.header__search-bar form input[type=search]::placeholder {
  color: #dd713a;
}
@media only screen and (min-width: 960px) {
  .header__search-bar form input[type=submit] {
    margin-left: 1rem;
  }
}
.header__search-bar form .screen-reader-text {
  display: none;
}
.header__nav {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
@media only screen and (min-width: 960px) {
  .header__nav {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
@media only screen and (min-width: 960px) {
  .header__trigger-menu {
    display: none;
  }
}
.header__trigger-search {
  justify-self: flex-end;
}
.header__trigger-search path.icon {
  stroke: #3c73a9;
}
.header__trigger-search button {
  cursor: pointer;
}
.header__list {
  display: none;
  white-space: nowrap;
}
@media only screen and (min-width: 960px) {
  .header__list {
    display: flex;
    justify-content: space-around;
    grid-column: span 5;
  }
}
.header__logo {
  grid-column: span 4;
}
@media only screen and (min-width: 960px) {
  .header__logo {
    grid-column: span 2;
  }
}
.header__logo img {
  border-radius: 0;
}
@media only screen and (min-width: 960px) {
  .header__logo img {
    width: 220px;
  }
}
.header__link {
  color: #3c73a9;
}
.header__link.link-active {
  color: #dd713a;
  border-bottom: 1px solid #dd713a;
  padding-bottom: 0.5rem;
}
.header svg {
  width: auto;
  height: 1.5rem;
}

.mobile-menu {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  padding: 2rem;
  transform: translateY(-100%);
  transition-duration: 300ms;
}
@media only screen and (min-width: 960px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu.is-open {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transform: translateY(0);
}
.mobile-menu__topbar {
  display: flex;
}
.mobile-menu__topbar img {
  margin-left: 1rem;
}
.mobile-menu__list {
  padding-top: 2rem;
}
.mobile-menu__item {
  padding: 1rem 0;
}
.mobile-menu__link {
  color: #3c73a9;
  font-size: 1.25rem;
}

.footer {
  background-color: #3c73a9;
  padding: 1.5rem 0 1rem 0;
}
@media only screen and (min-width: 960px) {
  .footer {
    padding-top: 2rem;
  }
}
.footer * {
  color: #ffffff;
}
.footer__grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-bottom: 1px solid #ffffff;
  padding-bottom: 1rem;
}
@media only screen and (min-width: 960px) {
  .footer__grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-bottom: 2rem;
  }
}
.footer__logo-container {
  grid-column: span 2;
}
@media only screen and (min-width: 960px) {
  .footer__logo-container {
    grid-column: span 1;
  }
}
.footer__logo {
  background-color: #ffffff;
  padding: 1.5rem 0;
  margin-left: -1.75rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  margin-bottom: 2rem;
  margin-right: 5rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.footer__logo img {
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .footer__logo {
    margin-left: calc(-50vw + 200%);
    padding-left: calc(50vw - 200%);
    margin-right: 0;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    padding-right: 4rem;
  }
}
.footer__email {
  font-family: "Colour Sans", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
}
@media only screen and (min-width: 960px) {
  .footer__email {
    font-size: 1.5rem;
  }
}
.footer__linkedin {
  display: flex;
  margin-top: 1rem;
}
.footer__linkedin li a {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  font-size: 0.875rem;
}
.footer__linkedin--desktop {
  display: none;
}
@media only screen and (min-width: 960px) {
  .footer__linkedin--desktop {
    display: flex;
    grid-column: span 4;
    justify-content: flex-end;
  }
}
.footer__links h3 {
  margin-bottom: 0;
}
.footer__links--info, .footer__links--menu {
  display: none;
}
@media only screen and (min-width: 960px) {
  .footer__links--info, .footer__links--menu {
    display: block;
  }
}
@media only screen and (min-width: 960px) {
  .footer__links--mobile {
    display: none;
  }
}
.footer__item {
  padding: 0.25rem 0;
}
@media only screen and (min-width: 960px) {
  .footer__item {
    padding: 0.5rem 0;
  }
}
.footer__link {
  font-size: 0.875rem;
}
@media only screen and (min-width: 960px) {
  .footer__link {
    font-size: 1rem;
  }
}
.footer__legal {
  display: flex;
  padding: 0.5rem 0;
  justify-content: space-around;
}
.footer__legal li a {
  font-size: 0.875rem;
}
@media only screen and (min-width: 960px) {
  .footer__legal li a {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 960px) {
  .footer__legal {
    display: none;
  }
}

/** Script imports  */
.hero {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 960px) {
  .hero {
    justify-content: baseline;
    height: 35.75rem;
  }
}
.hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.2);
}
.hero h1 {
  position: relative;
  text-align: center;
  color: #ffffff;
  z-index: 10;
}
@media only screen and (min-width: 960px) {
  .hero h1 {
    max-width: 34rem;
    text-align: left;
  }
}

@media only screen and (min-width: 960px) {
  .intro {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .intro > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }
}
.intro__content {
  margin-bottom: 4rem;
}
@media only screen and (min-width: 960px) {
  .intro__content {
    max-width: 454px;
  }
}
.intro__title {
  margin-bottom: 2rem;
}
.intro__text {
  margin-bottom: 4rem;
}
.intro__image img {
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .intro__image {
    margin-right: calc(-50vw + 100%);
  }
  .intro__image img {
    max-width: unset;
    width: 100%;
    max-height: 680px;
    object-fit: cover;
    object-position: center center;
  }
  .intro__image img.rounded {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.intro__payoff {
  position: relative;
  color: #3c73a9;
}
.intro__payoff::after {
  content: "";
  position: absolute;
  bottom: -25%;
  left: -1.75rem;
  width: calc(100% + 1.75rem);
  height: 0.5rem;
  background-color: #dbdc62;
}
@media only screen and (min-width: 960px) {
  .intro__payoff::after {
    z-index: -1;
    width: 100vw;
    left: calc(-50vw + 100%);
  }
}

.activities {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 4rem;
  background-size: cover;
  background-position: center center;
}
@media only screen and (min-width: 960px) {
  .activities {
    padding-top: 7rem;
    padding-bottom: 4.75rem;
  }
}
.activities * {
  color: #ffffff;
}
.activities::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: linear-gradient(360deg, rgba(60, 115, 169, 0.92) 0%, rgba(60, 115, 169, 0.59) 100%);
}
.activities__content {
  position: relative;
  z-index: 10;
}
.activities__title {
  margin-bottom: 1.5rem;
}
@media only screen and (min-width: 960px) {
  .activities__title {
    text-align: center;
    margin-bottom: 2.75rem;
  }
}
.activities__text {
  margin-bottom: 4rem;
}
@media only screen and (min-width: 960px) {
  .activities__text {
    text-align: center;
    max-width: 462px;
    margin: 0 auto;
    margin-bottom: 3.5rem;
  }
}
.activities__readmore {
  display: block;
  margin-bottom: 2rem;
}
@media only screen and (min-width: 960px) {
  .activities__readmore {
    text-align: center;
  }
}
@media only screen and (min-width: 960px) {
  .activities__list {
    display: flex;
    justify-content: space-between;
    max-width: 652px;
    margin: 0 auto;
  }
}
.activities__item {
  margin: 1.5rem 0;
}
.activities__link {
  font-family: "Colour Sans";
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding: 0.25rem 0;
}
.activities__link::after {
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 16'%3E%3Cpath d='M24.707 7.293a1 1 0 0 1 0 1.414l-6.364 6.364a1 1 0 0 1-1.414-1.414L22.586 8l-5.657-5.657A1 1 0 0 1 18.343.93l6.364 6.364ZM0 7h24v2H0V7Z' fill='%23fff'/%3E%3C/svg%3E");
  width: 1.5rem;
  position: relative;
  transform: translateY(25%);
  height: 1rem;
  margin-left: 0.5rem;
  display: inline-block;
}

.values {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.values__title {
  text-align: center;
}
.values__illustration {
  margin-left: auto;
  margin-right: auto;
  max-width: 71.25em;
}
.values__illustration img {
  width: 100%;
}
.values__illustration .swiper-slide {
  padding: 0 1.75rem;
}
@media only screen and (min-width: 960px) {
  .values__illustration .swiper-slide {
    padding: 0;
  }
}
.values__illustration .swiper-scrollbar {
  max-width: 212px;
  left: 50% !important;
  transform: translateX(-50%);
  background: rgba(196, 196, 196, 0.7);
}

.history {
  background: linear-gradient(180deg, #f5f5f5 0%, rgba(245, 245, 245, 0) 100%);
}
@media only screen and (min-width: 960px) {
  .history > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 7.5rem;
    align-items: center;
  }
}
@media only screen and (min-width: 960px) {
  .history {
    background-color: #f5f5f5;
  }
}
@media only screen and (min-width: 960px) {
  .history__content {
    grid-column-start: 2;
  }
}
.history__illustration {
  position: relative;
  max-height: 440px;
  overflow: hidden;
  grid-column-start: 1;
  grid-row-start: 1;
}
.history__readmore {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 128.46%);
  color: #dd713a;
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 700;
  padding: 1rem;
  width: 100%;
  height: 120px;
  cursor: pointer;
}
.history__readmore svg {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

@media only screen and (min-width: 960px) {
  .jobs > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }
}
.jobs__cta {
  margin-top: 4rem;
  margin-bottom: 5.675rem;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 960px) {
  .jobs__cta {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 960px) {
  .jobs__content {
    padding-right: 8rem;
  }
}
@media only screen and (min-width: 960px) {
  .jobs__images {
    padding: 0 4rem;
    margin-right: calc(-50vw + 100%);
    max-width: 768px;
  }
  .jobs__images .swiper {
    padding-right: 2rem;
  }
  .jobs__images .swiper-wrapper {
    height: 760px;
  }
  .jobs__images .swiper-slide {
    width: 100%;
  }
  .jobs__images .swiper-vertical > .swiper-scrollbar {
    max-height: 10rem;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}
.jobs__images .swiper-horizontal > .swiper-scrollbar {
  max-width: 212px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(196, 196, 196, 0.7);
}
@media only screen and (min-width: 960px) {
  .jobs__images .swiper-wrapper {
    height: 760px;
  }
  .jobs__images .swiper-vertical > .swiper-scrollbar {
    max-width: 212px;
    left: auto;
    right: 0;
  }
}
.jobs__image img {
  height: 20rem;
  object-fit: cover;
  object-position: center center;
  margin-bottom: 2rem;
}
.jobs__image img:first-of-type {
  margin-left: 1.75rem;
}
.jobs__image img:last-of-type {
  margin-right: 1.75rem;
}
@media only screen and (min-width: 960px) {
  .jobs__image img {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
  .jobs__image img:first-of-type {
    margin-left: 0;
  }
  .jobs__image img:last-of-type {
    margin-right: 0;
  }
}

.language-selector button {
  font-family: "Colour Sans";
  font-size: 1.5rem;
  cursor: pointer;
}
.language-selector .active {
  color: #dd713a;
  font-weight: bold;
  text-decoration: underline;
}

.patient-products {
  margin-bottom: 5rem;
}
.patient-products h2 {
  text-align: center;
  margin-bottom: 4rem;
}
.patient-products__card {
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  padding-top: 2.5rem;
  height: 100%;
}
.patient-products__card h3 {
  font-size: 2rem;
  color: #3c73a9;
  margin: 0;
  margin-bottom: 0;
}
.patient-products__subtitle {
  line-height: 1.33;
  min-height: 2.66em;
  border-bottom: 1px solid #dd713a;
  margin: 0;
  padding: 0.5rem 0;
}
.patient-products__text {
  padding: 1rem 0;
}
.patient-products .swiper,
.patient-products .swiper-wrapper {
  overflow: hidden;
}
@media only screen and (min-width: 960px) {
  .patient-products .swiper,
.patient-products .swiper-wrapper {
    overflow: visible;
  }
}
.patient-products .swiper-slide {
  align-self: stretch;
  height: auto;
}
.patient-products__items > div > div {
  display: grid;
  gap: 1rem;
}
@media only screen and (min-width: 960px) {
  .patient-products__items > div > div {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
  }
}

.hcp__main-title {
  text-align: center;
  margin-bottom: 3.5rem;
}
.hcp-product__category {
  position: relative;
  padding-bottom: 2rem;
}
@media only screen and (min-width: 960px) {
  .hcp-product__category {
    padding-bottom: 0;
  }
}
.hcp-product__category::after {
  content: "";
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  position: absolute;
  bottom: 1rem;
  background-color: #dd713a;
}
@media only screen and (min-width: 960px) {
  .hcp-product__category::after {
    display: none;
  }
}
@media only screen and (min-width: 960px) {
  .hcp-product__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 960px) {
  .hcp-product__card {
    padding: 2.625rem 3.25rem;
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
    border-radius: 1.25rem;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 8rem;
  }
}
.hcp-product__card-title {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}
@media only screen and (min-width: 960px) {
  .hcp-product__card-title {
    font-size: 2rem;
  }
}
.hcp-product__card-subtitle {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  display: inline-flex;
}
@media only screen and (min-width: 960px) {
  .hcp-product__card-subtitle {
    border-bottom: 1px solid #dd713a;
    font-size: 1.5rem;
  }
}
.hcp-product__card-list {
  margin-bottom: 2rem;
}
.hcp-product__card-item {
  margin-bottom: 1rem;
  max-width: 400px;
}
.hcp-product__card-link {
  display: flex;
  background: #ffffff;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0.5rem 2rem;
  transition-duration: 200ms;
}
.hcp-product__card-link:hover {
  background-color: #3c73a9;
  color: #ffffff;
}
@media only screen and (min-width: 960px) {
  .hcp-product__card-link {
    font-size: 1.5rem;
  }
}

.courses > .container {
  display: grid;
}
@media only screen and (min-width: 960px) {
  .courses > .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
  }
}
.courses__list {
  margin-top: 5rem;
}
.courses__item {
  margin-bottom: 0.75rem;
}
@media only screen and (min-width: 960px) {
  .courses__content {
    padding-left: 8rem;
  }
}
.courses__image {
  grid-row-start: 1;
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}
.courses__image img {
  width: 100%;
  border-radius: 0;
}
@media only screen and (min-width: 960px) {
  .courses__image {
    grid-row-start: auto;
    margin-left: calc(-50vw + 100%);
    margin-right: 0;
  }
  .courses__image img {
    max-width: unset;
    width: 100%;
    min-height: 480px;
    max-height: 680px;
    object-fit: cover;
    object-position: center center;
  }
  .courses__image img.rounded {
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.courses__link {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  transition-duration: 200ms;
  border-bottom: 1px solid transparent;
}
.courses__link strong {
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 700;
}
.courses__link:hover {
  color: #dd713a;
  border-bottom: 1px solid #dd713a;
}
.courses__link svg {
  margin-left: 1rem;
}

.partnerships {
  background-color: #f5f5f5;
}
@media only screen and (min-width: 960px) {
  .partnerships > .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media only screen and (min-width: 960px) {
  .partnerships__content {
    padding-left: 8rem;
  }
}
.partnerships__link {
  margin-left: 2rem;
  display: flex;
  flex-wrap: wrap;
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
}
.partnerships__link-name {
  font-weight: 700;
}
.partnerships .swiper-wrapper {
  height: 480px;
}
.partnerships .swiper-vertical > .swiper-scrollbar {
  left: 0;
  right: auto;
  height: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.cdmo-services__title {
  text-align: center;
  margin-bottom: 4rem;
}
.cdmo-services__items {
  display: grid;
  gap: 1.5rem;
  align-items: flex-start;
}
@media only screen and (min-width: 960px) {
  .cdmo-services__items {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.cdmo-services__card {
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.cdmo-services__card-header {
  height: 16em;
}
.cdmo-services__card-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.cdmo-services__card-title {
  font-family: "Colour Sans";
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  color: #3c73a9;
  margin: 2rem 0;
  padding: 0 2rem;
  min-height: 2em;
}
.cdmo-services__card-list {
  padding: 0 1.625rem;
  margin-bottom: 1rem;
  max-height: 8rem;
}
.cdmo-services__card-item {
  padding: 0.5rem 0;
  line-height: 1.5;
  border-bottom: 1px solid rgba(18, 24, 31, 0.2);
}
.cdmo-services__card-item:last-of-type {
  border-bottom: none;
}
.cdmo-services__card-overlay {
  display: block;
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  left: 0;
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 128.46%);
  height: 8rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
}
.cdmo-services__card-overlay button {
  pointer-events: none;
}
.cdmo-services__card-overlay svg {
  margin-bottom: 1rem;
}

.cdmo-footer {
  background-color: #f5f5f5;
  margin-top: 5rem;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer {
    margin-top: 0;
  }
}
.cdmo-footer > .container {
  display: grid;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer > .container {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 4.5rem;
  }
}
.cdmo-footer__brochure {
  grid-row-start: 2;
  margin-top: 2rem;
  text-align: center;
}
.cdmo-footer__brochure img {
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__brochure {
    text-align: left;
    margin-top: 0rem;
    grid-row-start: auto;
    grid-column: span 4;
  }
}
.cdmo-footer__brochure-text {
  font-size: 1rem;
  line-height: 1.33;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__brochure-text {
    font-size: 1.5rem;
  }
}
.cdmo-footer__brochure button {
  margin: 0 auto;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__brochure button {
    margin: unset;
  }
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__testimonials {
    grid-column: 5/span 8;
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
    border-radius: 60px;
    padding: 3rem 5rem;
  }
}
.cdmo-footer__testimonials-title {
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  margin-top: 0;
}
.cdmo-footer__testimonials-services {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}
.cdmo-footer__testimonials-services li {
  margin-right: 1rem;
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  color: #3c73a9;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__testimonials-services li {
    margin-right: 3rem;
  }
}
.cdmo-footer__testimonials-services::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: -1.75rem;
  width: calc(100% + 3.5rem);
  height: 8px;
  background-color: #dbdc62;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__testimonials-services::after {
    left: -5rem;
    width: calc(100% + 10rem);
  }
}
.cdmo-footer__testimonials blockquote {
  font-family: "Colour Sans";
  font-size: 1.5rem;
  line-height: 1.33;
  margin: 0;
  margin-top: 4rem;
  position: relative;
}
.cdmo-footer__testimonials blockquote::before {
  content: "“";
  position: absolute;
  left: -1rem;
  top: -1rem;
  font-size: 2rem;
  color: #dd713a;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__testimonials blockquote::before {
    left: -2rem;
    top: -2rem;
    font-size: 4rem;
  }
}
.cdmo-footer__testimonials blockquote::after {
  content: "”";
  position: absolute;
  bottom: 0rem;
  margin-left: 0.5rem;
  font-size: 2rem;
  color: #dd713a;
}
@media only screen and (min-width: 960px) {
  .cdmo-footer__testimonials blockquote::after {
    font-size: 4rem;
    bottom: -2rem;
  }
}
.cdmo-footer__testimonials-author {
  display: block;
  text-align: right;
  font-family: "Colour Sans";
  font-weight: 700;
  font-size: 1.5rem;
}

.order-information__header {
  padding-bottom: 0;
}
@media only screen and (min-width: 960px) {
  .order-information__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 8rem;
  }
}
.order-information__block {
  margin-bottom: 2rem;
}
.order-information__title {
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.33;
  margin-bottom: 0;
  color: #3c73a9;
}
.order-information__text {
  line-height: 1.5;
}
.order-information__text a {
  color: #dd713a;
  border-bottom: 1px solid #dd713a;
  padding-bottom: 0.15rem;
}
.order-information__card {
  margin-top: 2rem;
  background: #ffffff;
  box-shadow: 0px 3.58484px 43.0181px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  padding: 1.5rem;
}
@media only screen and (min-width: 960px) {
  .order-information__card {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.order-information__list {
  padding-left: 32px;
  margin-bottom: 0.5rem;
}
.order-information__list li {
  line-height: 1.5;
}
.order-information__list::before {
  content: "";
  background-size: 100% 100%;
  width: 18px;
  height: 20px;
  vertical-align: middle;
  margin-left: -32px;
  position: absolute;
}
.order-information__list--address::before {
  background-image: url("../images/location.svg");
}
.order-information__list--mail::before {
  background-image: url("../images/mail.svg");
}
.order-information__list--phone {
  margin-bottom: 0;
}
.order-information__list--phone::before {
  background-image: url("../images/phone.svg");
}

.pdp-downloadables {
  display: grid;
  gap: 1.5rem;
  align-items: baseline;
}
@media only screen and (min-width: 960px) {
  .pdp-downloadables {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.pdp-downloadables__item {
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.pdp-downloadables__item h3 {
  color: #3c73a9;
  padding-top: 2.75rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #dd713a;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2.25rem;
}
.pdp-downloadables__expand-btn {
  margin: 0 auto;
  display: block;
  margin-bottom: 2.25rem;
  cursor: pointer;
}
.pdp-downloadables__expand-btn svg {
  pointer-events: none;
}
.pdp-downloadables__empty {
  text-align: center;
  margin-bottom: 2rem;
  opacity: 0.5;
}
.pdp-downloadables__content {
  display: none;
}
.pdp-downloadables__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.25rem;
}
.pdp-downloadables__list li {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.pdp-downloadables__section-title {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: #3c73a9;
}

.pdp-faq__main-title {
  text-align: center;
  margin-bottom: 4rem;
}
.pdp-faq__bg {
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
}
.pdp-faq__list {
  padding: 3rem 0;
}
.pdp-faq__question {
  border-bottom: 1px solid #dd713a;
  padding: 1rem 0;
}
.pdp-faq__question-title {
  font-family: "Colour Sans";
  font-weight: 400;
  font-size: 1.5rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
}
.pdp-faq__question-title span {
  pointer-events: none;
}
.pdp-faq__question-title svg {
  min-width: 1rem;
}
.pdp-faq__question-answer {
  padding: 1rem;
  display: none;
}
.pdp-faq__question-answer ul,
.pdp-faq__question-answer ol {
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pdp-faq__question-answer ul li,
.pdp-faq__question-answer ol li {
  list-style-type: disc;
  padding: 0.25rem 0;
}
.pdp-faq__question-answer ol li {
  list-style-type: decimal;
}
.pdp-faq__question-answer--visible {
  display: block;
}
.pdp-faq__question-chevron--is-open {
  transform: rotate(180deg);
}

.p-course {
  padding: 2rem 0;
  background-color: #f5f5f5;
}
.p-course__container {
  display: grid;
}
@media only screen and (min-width: 960px) {
  .p-course__container {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
.p-course__subscribe-title {
  font-family: "Colour Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 2rem;
}
@media only screen and (min-width: 960px) {
  .p-course__event-meta {
    grid-column: 5 span;
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-right: 4rem;
  }
}
.p-course__event-meta-title {
  font-family: "Colour Sans";
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #3c73a9;
  margin-bottom: 4rem;
}
.p-course__event-meta-item {
  font-family: "Colour Sans";
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
.p-course__event-meta-item::before {
  content: "";
  background-size: 100% 100%;
  width: 18px;
  height: 20px;
  vertical-align: middle;
  margin-left: -32px;
  position: absolute;
}
.p-course__event-meta-item--date::before {
  background-image: url("../images/date.svg");
}
.p-course__event-meta-item--address::before {
  background-image: url("../images/location.svg");
}
.p-course__event-meta-item--time::before {
  background-image: url("../images/time.svg");
}
.p-course__event-meta-item--teacher::before {
  background-image: url("../images/speaker.svg");
}
@media only screen and (min-width: 960px) {
  .p-course__description {
    grid-column: 7 span;
    background: #ffffff;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.15);
    border-radius: 60px;
    padding: 4rem;
  }
}

.news__specifics--item {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  align-items: center;
}
.news__specifics--list {
  margin-bottom: 2rem;
}
.news .page-header {
  padding-bottom: 1rem;
}
@media only screen and (max-width: 960px) {
  .news .page-header {
    padding-bottom: 0;
  }
}
.news .page-header .icon {
  height: 1.5rem;
  width: 1.5rem;
}
.news .page-header .icon img {
  height: 100%;
  width: 100%;
}
.news-main {
  padding-top: 0;
  padding-bottom: 0;
}
.news-main__content h2 {
  font-size: 2rem;
  margin-bottom: 0rem;
}

.jobs-header__actions {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.content-disclaimer {
  padding-right: 4rem;
  font-size: 0.875rem;
}
@media only screen and (max-width: 960px) {
  .content-disclaimer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.jobs-image-slider__image:first-of-type {
  padding-left: 1rem;
}
.jobs-image-slider__image:last-of-type {
  margin-right: 1rem;
}
.jobs-image-slider__image img {
  margin-bottom: 2rem;
  width: 28rem;
  height: 18rem;
  object-fit: cover;
  object-position: center center;
}
.jobs-image-slider .swiper-scrollbar {
  max-width: 212px;
  left: 50% !important;
  transform: translateX(-50%);
  background: rgba(196, 196, 196, 0.7);
}

.jobs .swiper {
  margin-left: -1.75em;
  margin-right: -1.75em;
}
@media only screen and (min-width: 960px) {
  .jobs .swiper {
    margin-left: 0;
    margin-right: 0;
  }
}

.contact-information {
  background-color: #f5f5f5;
}
@media only screen and (min-width: 960px) {
  .contact-information > .container {
    display: flex;
  }
  .contact-information > .container > div {
    flex: 1;
  }
}
.contact-information__country-title {
  color: #3c73a9;
}
.contact-information__item {
  padding: 0.25rem 0 0.25rem 32px;
}
.contact-information__item ul li {
  padding: 0.25rem 0;
}
.contact-information__item::before {
  content: "";
  background-size: 100% 100%;
  width: 18px;
  height: 20px;
  vertical-align: middle;
  margin-left: -32px;
  position: absolute;
}
.contact-information__item--mail::before {
  background-image: url("../images/mail.svg");
}
.contact-information__item--phone-number::before {
  background-image: url("../images/phone.svg");
}
.contact-information__item--location::before {
  background-image: url("../images/location.svg");
}
.contact-information__item--post::before {
  background-image: url("../images/post.svg");
}
.contact-information__item--business::before {
  background-image: url("../images/business-card.svg");
}

.transportation-info {
  padding-bottom: 4rem;
}
.transportation-info__title {
  margin-bottom: 2rem;
}
.transportation-info__grid {
  display: grid;
  grid-template-columns: 42% 55%;
  gap: 3%;
}
@media only screen and (max-width: 960px) {
  .transportation-info__grid {
    grid-template-columns: 100%;
  }
}
.transportation-info__content {
  display: flex;
  flex-direction: column;
}
.transportation-info__block h3 {
  line-height: 1;
}
.transportation-info__text ul {
  list-style: disc;
  list-style-type: disc;
  padding-inline-start: 40px;
}
.transportation-info__text ul li {
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
}
.transportation-info__text ul li strong {
  font-weight: bold;
}
.transportation-info__text ul li a {
  color: #3c73a9;
  text-decoration: underline;
}
.transportation-info__image-link {
  display: block;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.transportation-info__image-link:hover {
  opacity: 0.9;
}
.transportation-info__image img {
  width: 100%;
  height: auto;
  display: block;
}
.transportation-info__image-top {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 960px) {
  .page-template-template-contact .page-header .container {
    display: block;
  }
}

.gform_heading {
  display: none;
}

.gform_confirmation_wrapper {
  margin-top: 2rem;
}

.gform_body {
  margin-top: 2rem;
}
.gform_body input,
.gform_body textarea {
  border: 1px solid #3c73a9;
  border-radius: 0.5rem;
}
.gform_body select {
  border: 1px solid #3c73a9;
  border-radius: 0.5rem;
  margin-top: 2rem;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.gform_body .gfield,
.gform_body .ginput_complex {
  font-family: "Colour Sans";
  margin-top: 0.5rem;
}
.gform_body #field_1_8 button {
  display: block;
  font-family: "Colour Sans";
  color: white;
  font-weight: bold;
  width: max-content;
}

.p-simple-content,
.p-404 {
  padding-top: 0;
  padding-bottom: 2rem;
  max-width: 768px;
  margin: 0 auto;
}
.p-simple-content h1,
.p-404 h1 {
  color: #3c73a9;
  text-transform: uppercase;
}
.p-simple-content ul,
.p-simple-content ol,
.p-404 ul,
.p-404 ol {
  padding-left: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.p-simple-content ul li,
.p-simple-content ol li,
.p-404 ul li,
.p-404 ol li {
  list-style-type: disc;
  padding: 0.25rem 0;
}
.p-simple-content ol li,
.p-404 ol li {
  list-style-type: decimal;
}
@media only screen and (min-width: 960px) {
  .p-simple-content,
.p-404 {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
}
.p-simple-content p,
.p-404 p {
  text-align: justify;
}
.p-simple-content h3,
.p-404 h3 {
  margin-bottom: 0.5rem;
}
.p-simple-content h3 + p,
.p-404 h3 + p {
  margin-top: 0;
}

.p-404 h1,
.p-404 p {
  text-align: center;
}
.p-404 a {
  color: #dd713a;
  border-bottom: 1px solid #dd713a;
}

.media-center-part {
  padding-bottom: 10rem;
}
@media only screen and (max-width: 960px) {
  .media-center-part {
    padding-top: 0;
    padding-bottom: 6rem;
  }
}

.media-center__box {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
@media only screen and (max-width: 960px) {
  .media-center__box {
    grid-template-columns: 1fr;
  }
}
.media-center__menu {
  grid-column: span 2;
}
@media only screen and (max-width: 960px) {
  .media-center__menu {
    grid-column: span 1;
  }
}
@media only screen and (max-width: 960px) {
  .media-center__menu--list {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
.media-center__menu--item-btn {
  cursor: pointer;
  font-family: "Colour Sans";
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 1.25rem;
  color: inherit;
  font-weight: 300;
}
@media only screen and (max-width: 960px) {
  .media-center__menu--item-btn {
    padding-left: 0;
  }
}
.media-center__menu--item-btn.active {
  font-weight: 700;
  color: #dd713a;
  text-decoration: underline;
}
.media-center__posts {
  grid-column: span 6;
}
.media-center__posts--list:not(.active) {
  display: none;
}
.media-center__posts--item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dd713a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.media-center__posts--item h4 {
  font-family: "Colour Sans";
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.media-center__posts--item p {
  margin-top: 0rem;
  margin-bottom: 0;
}